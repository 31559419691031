<template>
  <div class="nav">
    <client-only>
      <nuxt-link to="/m" class="nav__left">
        <!-- <img v-if="isTrend" src="@/assets/style/mobile/img/nav/iconHome@2x.png" alt=""> -->
      </nuxt-link>
      <a class="nav__logo" @click="showLotteryList">
        <span v-if="!isTrend">{{ HeaderTitle }}</span>
        <a v-else href="javascript:;" class="nav-dropdown">
          <span class="nav-dropdown__title">{{ HeaderTitle }}</span>
          <img class="nav-dropdown__img" src="@/assets/style/mobile/img/nav/chevron-down-solid.png">
        </a>
      </a>
      <div class="nav__right">
        <a v-if="isHome && !isPDSWAPP && !isIos" :href="appLink" target="_blank">
          <img class="app-download" src="@/assets/frontend/app-download.png" alt="">
        </a>
        <a v-if="isTrend" class="explain" @click.prevent="showRulePopup">说明</a>
      </div>
    </client-only>
    <HeadTabPopup :show.sync="show" />
  </div>
</template>

<script>
import { isPDSWAPP, sendMessageToPDSW, isIos } from '@/utils/commonLib'
import HeadTabPopup from '@/components/mobile/common/HeadTabPopup'

export default {
  name: 'WapHeader',
  components: {
    HeadTabPopup
  },
  data() {
    return {
      show: false,
      // 檢查是不是在自家的app 裡
      isPDSWAPP: isPDSWAPP(),
      // 檢查是不是 ios system
      isIos: isIos()
    }
  },
  computed: {
    // 彩種基本資料 (頻率, 中文名, 休市日, icon)
    cpsDetail() {
      return this.$store.state.lottery.cpsDetail
    },
    // app 下載連結
    appLink() {
      return this.$store.state.app.apkLink.url
    },
    isHome() {
      return this.$route.path === '/m'
    },
    HeaderTitle() {
      // 首頁設置 api 站名
      if (this.$route.path === '/m' || this.$route.path === '/m/') {
        return this.$store.state.app.siteName
      } else if (this.$route.path.includes('/trend')) {
        // 設置 trend 標題
        return this.cpsDetail[this.$route.params.lottery_code].name
      } else if (this.$route.path.includes('/images')) {
        return '六合图库'
      } else if (this.$route.path.includes('/cltx')) {
        return '长龙提醒'
      } else if (this.$route.path.includes('/property')) {
        return '属性参照'
      }

      return '六合标题'
    },
    // 判斷是否為走勢頁路由
    isTrend() {
      return this.$route.path.includes('/trend')
    }
  },
  methods: {
    showLotteryList() {
      if (isPDSWAPP()) {
        sendMessageToPDSW('Router', 'hideBackBtn', '')
      }
      if (this.isTrend) {
        this.show = true
      }
    },
    showRulePopup() {
      if (this.isTrend) {
        const params = {
          type: 'hk6',
          popupIsOpen: true
        }
        this.$emit('lotteryInfo', params)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .nav {

    .nav__logo {
      display: flex;
      margin: 0 15px;
    }

    .app-download {
      width: 36px;
      height: 36px;
      margin-right: 12px;
      margin-top: 8px;
    }
  }

  .float-right {
    float: left;
  }
</style>
