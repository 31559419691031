<template>
  <div class="layout-tabbar">
    <div class="tabbar">
      <nuxt-link to="/m" class="tabbar__item" :class="{ 'tabbar__item--active': $route.path === '/m' }">
        <div class="tabbar__item__icon">
          <img v-if="$route.path === '/m'" src="@/assets/style/mobile/img/index-co168/icon-img1-Active@2x.png" alt="首页">
          <img v-else src="@/assets/style/mobile/img/index-co168/icon-img1@2x.png" alt="首页">
        </div>
        <span>首页</span>
      </nuxt-link>
      <nuxt-link to="/m/images" class="tabbar__item" :class="{ 'tabbar__item--active': $route.path.includes('images') }">
        <div class="tabbar__item__icon">
          <img v-if="$route.path.includes('images')" src="@/assets/style/mobile/img/index-co168/icon-img-Active@2x.png" alt="六合图库">
          <img v-else src="@/assets/style/mobile/img/index-co168/icon-img@2x.png" alt="六合图库">
        </div>
        <span>六合图库</span>
      </nuxt-link>
      <nuxt-link to="/m/cltx" class="tabbar__item" :class="{ 'tabbar__item--active': $route.path.includes('cltx') }">
        <div class="tabbar__item__icon">
          <img v-if="$route.path.includes('cltx')" src="@/assets/style/mobile/img/index-co168/icon-img2-Active@2x.png" alt="长龙提醒">
          <img v-else src="@/assets/style/mobile/img/index-co168/icon-img2@2x.png" alt="长龙提醒">
        </div>
        <span>长龙提醒</span>
      </nuxt-link>
      <nuxt-link to="/m/property" class="tabbar__item" :class="{ 'tabbar__item--active': $route.path.includes('property') }">
        <div class="tabbar__item__icon">
          <img v-if="$route.path.includes('property')" src="@/assets/style/mobile/img/index-co168/icon-img4-Active@2x.png" alt="属性参照">
          <img v-else src="@/assets/style/mobile/img/index-co168/icon-img4@2x.png" alt="属性参照">
        </div>
        <span>属性参照</span>
      </nuxt-link>
      <a class="tabbar__item" @click="pushRoute()">
        <div class="tabbar__item__icon">
          <img src="@/assets/style/mobile/img/index-co168/icon-img3@2x.png" alt="开奖网">
          <!-- <img src="@/assets/style/mobile/img/index-co168/icon-img3-Active@2x.png" alt="开奖网"> -->
        </div>
        <span>开奖网</span>
      </a>
    </div>
  </div>
</template>

<script>
import { isPDSWAPP, sendMessageToPDSW } from '@/utils/commonLib'

export default {
  name: 'WapFooter',
  methods: {
    pushRoute() {
      if (isPDSWAPP()) {
        sendMessageToPDSW('Router', 'intent', process.env.MARKSIX_SERVER_HOST)
      } else {
        window.open(process.env.MARKSIX_SERVER_HOST)
      }
    }
  }
}
</script>

<style lang="scss">

</style>
