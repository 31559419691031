import Axios from 'axios'

const requestInstance = Axios.create({
  baseURL: process.server ? `${process.env.API_PROXY_HOST}` : '/api',
  timeout: 20000 // request timeout
})

// request interceptor
requestInstance.interceptors.request.use(
  // 請求送出去之前: 可以在這邊加上共用的header 之類的，如token 等等
  (config) => {
    config.headers = {
      'X-Site-Id': 20,
      'X-Sub-Template-Id': 7,
      'X-Site-Group-Id': 5
    }
    return config
  },

  // do something with request error
  (error) => Promise.reject(error)
)

// response interceptor
requestInstance.interceptors.response.use(
  (response) => {
    const data = response.data ? response.data : {}
    const status = data.status

    switch (status) {
      case 1:
        return [data.data, null]
      default:
        return [null, data]
    }
  },
  (error, config) => {
    // TODO: global error
    // server-side 要倒轉、client-side 好像也要?
    const errorResponse =
      error.response && error.response.data ? error.response.data : error
    return [null, errorResponse]
  }
)

export default requestInstance
