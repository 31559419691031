import Vue from 'vue'
// import FunctionalCalendar from 'vue-functional-calendar'
// import VCalendar from 'v-calendar'
import Calendar from './calendarToInstall'

// Vue.use(FunctionalCalendar, {
//   dayNames: ['一', '二', '三', '四', '五', '六', '日'],
//   monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
//   shortMonthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
// })
// Vue.use(VCalendar)
Vue.use(Calendar)
