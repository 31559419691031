import Vue from 'vue'
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/bar'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/polar'
import 'echarts/lib/component/toolbox'

const component = {
  install: function(Vue) {
    Vue.component('v-chart', ECharts)
  }
}

Vue.use(component)
