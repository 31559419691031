import request from '@/utils/request'

// 首頁資訊

export const getHomeInfo = function() {
  return request({
    url: 'v1/cp168/wap/site/info',
    method: 'get'
  })
}

// 即時開獎 (支援從後台設定)
export const getCpsDetail = function(query) {
  return request({
    url: '/v1/cp168/wap/draw/infoDetail',
    method: 'get',
    params: query
  })
}
