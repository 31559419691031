<template>
  <div style="font-size: 15px;">
    <van-popup v-model="vanpopup" position="top" class="header-pop-up">
      <div class="header-container">
        <div class="header-item" @click="onCancel">返回</div>
        <h3>所有彩种</h3>
        <div class="header-item">
          <div style="width: 25px;"></div>
        </div>
      </div>

      <div class="body-container">
        <div class="cltj-table">
          <div class="table-list game-list">
            <div
              v-for="(lottery, lotteryIndex) in hotLottery"
              :key="`lottery-${lotteryIndex}`"
              class="table-list__item list-item"
              @click="changeParam(lottery)"
            >
              <p class="item-text">{{ cpsDetail[lottery].name | subString }}</p>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { isPDSWAPP, sendMessageToPDSW } from '@/utils/commonLib'

export default {
  name: 'HeadTabPopup',
  filters: {
    // 字數太長過濾掉六合彩
    subString(val) {
      return val.length > 6 ? val.replace('六合彩', '') : val
    }
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      test: false
    }
  },
  computed: {
    // 彩種基本資料 (頻率, 中文名, 休市日, icon)
    cpsDetail() {
      return this.$store.state.lottery.cpsDetail
    },
    // 熱門彩種
    hotLottery() {
      return this.$store.state.app.hotLottery
    },
    vanpopup: {
      get() {
        return this.show
      },
      set(value) {
        this.$emit('update:show', value)
      }
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.$nextTick(() => {
          document.querySelectorAll('.list-item').forEach((element, index) => {
            setTimeout(() => {
              element.classList.toggle('tab-active', true)
            }, index * 100)
          })
        })
      } else {
        document.querySelectorAll('.list-item').forEach((element) => {
          element.classList.toggle('tab-active', false)
        })
      }
    }
  },
  methods: {
    changeParam(param) {
      this.showBackBtn()
      this.$router.push({ path: `/m/trend/${param}` })
      this.vanpopup = false
    },
    onCancel() {
      this.showBackBtn()
      this.vanpopup = false
    },
    showBackBtn() {
      if (isPDSWAPP()) {
        sendMessageToPDSW('Router', 'showBackBtn', '')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header-container {
  height: 45px;
  background-color: #188aff;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-item {
  margin: 0 25px;
}

.body-container {
  // background-color: #efeff4;
  background-color: #ffffff;
}

.list-item {
  transition: all 0.7s;
  opacity: 0;
  transform: translateY(300px);
}

.cltj-table {
  .table-list__item {
    &.list-item {
      width: 29vw;
    }
  }
}

.tab-active {
  opacity: 1;
  transform: translateY(0px);
}

.header-pop-up {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  background-color: #188aff;
}
</style>
