const middleware = {}

middleware['deviceRedirect'] = require('../middleware/deviceRedirect.js')
middleware['deviceRedirect'] = middleware['deviceRedirect'].default || middleware['deviceRedirect']

middleware['getSEO'] = require('../middleware/getSEO.js')
middleware['getSEO'] = middleware['getSEO'].default || middleware['getSEO']

middleware['user_agent'] = require('../middleware/user_agent.js')
middleware['user_agent'] = middleware['user_agent'].default || middleware['user_agent']

export default middleware
