<template>
  <div>
    <transition name="layout" mode="out-in">
      <div v-if="firstLoading" key="firstLoading" class="first-loading">
        <div class="loading-content">
          努力加载中...
        </div>
      </div>
      <div v-else key="layoutMain" class="layout-main">
        <rule-popup :lottery-info="lotteryInfo" />
        <wap-header @lotteryInfo="getLotteryInfo($event)" />
        <div class="wap-content">
          <nuxt />
        </div>
        <wap-footer />
      </div>
    </transition>
  </div>
</template>

<script>
import WapHeader from '@/components/mobile/layout/WapHeader'
import WapFooter from '@/components/mobile/layout/WapFooter'
import RulePopup from '@/components/mobile/common/RulePopup'

export default {
  name: 'MobileLayout',
  components: {
    WapHeader,
    WapFooter,
    RulePopup
  },
  data() {
    return {
      lotteryInfo: {},
      firstLoading: true
    }
  },
  mounted() {
    setTimeout(() => {
      this.firstLoading = false
    }, 1000)
  },
  methods: {
    getLotteryInfo(event) {
      this.lotteryInfo = event
    }
  },
  head() {
    return {
      titleTemplate: `${this.$store.state.app.siteName}`,
      script: [
        { src: '/js/index.min.js' }
      ],
      style: [
        { type: 'text/css', cssText: require('@/assets/style/mobile/css/main.css') }
      ],
      meta: [{
        hid: 'description',
        name: 'description',
        content: this.$store.state.seo.metaDescription
      }, {
        hid: 'keywords',
        name: 'keywords',
        content: this.$store.state.seo.metaKeyword
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.first-loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eff2f5;

  .loading-content {
    font-size: 30PX;
    color: #666666;
    animation: loading 1.5s linear infinite;
  }
}

@keyframes loading {
  0%   {color: #666666}
  50%  {color: #999999}
  100% {color: #666666}
}

.wap-content {
  flex: 1;
  overflow-y: auto;
  // padding-bottom:$tabbar-h;
  padding-bottom: 52px;
}

</style>
