<template>
  <div class="toTop-button" @mouseover="isHover = true" @mouseout="isHover = false">
    <div class="position">
      <svg id="arrow" height="20" width="24" xmlns="http://www.w3.org/2000/svg">
        <path
          id="path1"
          d="M9,16 L9,12 L4,12 L12,4 L20,12 L15,12 L15,16 L9,16 Z"
          :stroke="innerPathColor"
          stroke-width="6"
          :fill="outerPathColor"
          stroke-linejoin="round"
        />
        <path
          id="path1"
          d="M9,16 L9,12 L4,12 L12,4 L20,12 L15,12 L15,16 L9,16 Z"
          :stroke="outerPathColor"
          stroke-width="3"
          :fill="outerPathColor"
          stroke-linejoin="round"
        />
      </svg>
      <div class="combine-shape">
        <span class="text">TOP</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FixTopButton',
  data() {
    return {
      isHover: false
    }
  },
  computed: {
    innerPathColor() {
      return this.isHover ? '#007aff' : '#fff'
    },
    outerPathColor() {
      return this.isHover ? '#fff' : '#c3c3c3'
    }
  }
}
</script>

<style lang="scss" scoped>
.toTop-button {
  position: relative;
  width: 40Px;
  height: 39Px;
  background-color: transparent;
  transform: scale(1.2);
  cursor: pointer;
  #arrow {
    position: absolute;
    top: -8Px;
    left: 8Px;
    z-index: 3;
  }
  .position {
    position: absolute;
    bottom: 0;
    width: 40Px;
    height: 29Px;
    background-color: #fafafa;
    border-radius: 3Px;
    z-index: 2;
    box-shadow: 0 2Px 4Px 0 rgba(0, 0, 0, 0.2);
  }
  .combine-shape {
    position: absolute;
    bottom: 0;
    width: 40Px;
    height: 21Px;
    line-height: 21Px;
    border-radius: 3Px;
    background-color: rgb(255, 255, 255);
    text-align: center;
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    z-index: 5;
    .text {
      display: inline-block;
      font-family: PingFangSC;
      font-size: 12Px;
      color: #000000;
    }
  }
}
.toTop-button:hover .position {
  background-color: #007aff;
}
.toTop-button:hover .text {
  color: #007aff;
}
</style>
