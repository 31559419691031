import request from '@/utils/request'

const getSEO = function(params = {}) {
  return request({
    url: '/v1/apis/seo/page',
    params
  })
}

export {
  getSEO
}
