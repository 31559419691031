import request from '@/utils/request'

// 首頁資訊

// 彩種基本資料 (頻率, 中文名, 休市日, icon)
export const getPublicCp = function() {
  return request({
    url: '/v1/public/cp',
    method: 'get'
  })
}
