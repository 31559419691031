const dayjs = require('dayjs')

// 檢查是不是在自家的app 裡
const isPDSWAPP = () => {
  return navigator.userAgent.includes('PDSW')
}

// 檢查是不是 ios system
const isIos = () => {
  return navigator.userAgent.includes('iPhone')
}

const sendMessageToPDSW = (callbackHandler, type, data) => {
  if (navigator.userAgent.includes('iPhone')) {
    window.webkit.messageHandlers[callbackHandler].postMessage({ method: type, url: data })
  } else {
    data ? window[callbackHandler][type](data) : window[callbackHandler][type]
  }
}

const isMobileDevice = (agent) => {
  const devices = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ]

  return devices.some((toMatchItem) => {
    return agent.match(toMatchItem)
  })
}

// Sean for homepage
const Countdown_HomePage = function(config) {
  // timer實體
  this.timer = null
  // server時間
  this.currentTime = new Date(config.currentTime)
  // 結束時間
  this.nextPeriodTime = new Date(config.nextPeriodTime)
  // 時間格式
  this.formatTime = null
  // 倒數間隔
  this.duration = config.duration ? config.duration : 1
  // 結束時的回呼
  this.finishedCallback = config.finishedCallback ? config.finishedCallback : Function.prototype
  // 結束時回呼的參數
  this.finishedParameter = config.finishedParameter ? config.finishedParameter : {}
  // 倒數中的回呼
  this.tickerCallback = config.tickerCallback ? config.tickerCallback : Function.prototype
  // 倒數中回呼的參數
  this.tickerParameter = config.tickerParameter ? config.tickerParameter : {}
  // 時間狀態
  this.timesUpStatus = false

  // 開始計時
  this.startTimer = () => {
    this.timer = window.setTimeout(() => {
      this.countdown()
      this.tickerCallback()
      if (this.nextPeriodTime - this.currentTime <= 0) {
        this.finishedCallback()
        this.kill()
        return
      }
      this.startTimer()
    }, this.duration * 1000)
  }

  // 秒數計算
  this.countdown = () => {
    this.currentTime = new Date(dayjs(this.currentTime).add(this.duration, 'seconds'))
    this.formatTime = this.pattern(this.currentTime, this.nextPeriodTime)
  }
  // 時間轉換
  this.pattern = (current, next) => {
    const msec = next - current

    let _day = parseInt((msec / 1000 / 60 / 60 / 24) % 30)
    let _hour = parseInt((msec / 1000 / 60 / 60) % 24)
    let _min = parseInt((msec / 1000 / 60) % 60)
    let _sec = Math.ceil((msec / 1000) % 60)

    _day = _day > 9 ? `${_day}` : `0${_day}`
    _hour = _hour > 9 ? `${_hour}` : `0${_hour}`
    _min = _min > 9 ? `${_min}` : `0${_min}`
    _sec = _sec === 60 ? '00' : _sec > 9 ? `${_sec}` : `0${_sec}`

    return {
      mon: _day,
      hour: _hour,
      min: _min,
      sec: _sec
    }
  }

  // kill timer實體
  this.kill = () => { clearTimeout(this.timer) }
}
// flyc for other pages
const Countdown = function(config) {
  config = config || {}

  // sync prototype function
  var _format = Object.getPrototypeOf(this)._format
  var _milliSecondToPattern = Object.getPrototypeOf(this)._milliSecondToPattern

  // duplicate instance
  var _timer_instance = this

  // id
  var sequence = Object.getPrototypeOf(this).sequence++
  this.id = 'Countdown-' + sequence

  // ticker 間隔
  this.duration = config.duration ? config.duration : 1000
  // 結束時間
  this.closeTime = config.closeTime ? _format(config.closeTime) : _format(new Date())

  // 是否自動清除計時器
  this.killWhenFinished = config.killWhenFinished ? config.killWhenFinished : true

  // 每次間隔要做的事情
  this.tickerCallback = config.tickerCallback ? config.tickerCallback : Function.prototype
  this.tickerParameter = config.tickerParameter ? config.tickerParameter : {}

  // 倒數結束要做的事情
  this.finishedCallback = config.finishedCallback ? config.finishedCallback : Function.prototype
  this.finishedParameter = config.finishedParameter ? config.finishedParameter : {}

  // 時間文字
  this.leftTimeText = ''
  this.leftTime = {
    year: '',
    month: '',
    days: '',
    hours: '',
    minutes: '',
    seconds: ''
  }

  // 剩餘時間
  this.deltaMilliSecond = 0

  // 計時器本體
  this.timer = null

  // 開始計時
  this.start = function() {
    if (!_interval_function()) return

    this.timer = setInterval(_interval_function, this.duration)

    function _interval_function() {
      var currentMilliSecond = (new Date()).getTime()
      var closeMilliSecond = (new Date(_timer_instance.closeTime)).getTime()

      var deltaMilliSecond = closeMilliSecond - currentMilliSecond
      var leftTimeResult = _milliSecondToPattern(deltaMilliSecond)
      var leftTimeText = leftTimeResult.text
      var leftTime = leftTimeResult.map

      _timer_instance.deltaMilliSecond = deltaMilliSecond
      _timer_instance.leftTimeText = deltaMilliSecond > 0 ? leftTimeText : '-'
      _timer_instance.leftTime = Object.assign(_timer_instance.leftTime, leftTime)

      _timer_instance.tickerCallback(_timer_instance.tickerParameter)

      if (deltaMilliSecond <= 0) {
        if (_timer_instance.killWhenFinished) {
          _timer_instance.kill()
        }

        _timer_instance.finishedCallback(_timer_instance.finishedParameter)
        return false
      }

      return true
    }
  }

  // 清除計時器
  this.kill = function() {
    clearInterval(this.timer)
  }

  Object.getPrototypeOf(this).allCountdownInstances.push(this)
}
Countdown.prototype.sequence = 0
Countdown.prototype.allCountdownInstances = []
Countdown.prototype.formatPattern = 'YYYY-MM-DD HH:mm:ss'
Countdown.prototype.killAll = function killAll() {
  this.allCountdownInstances.forEach(function(dateInstance) {
    return dateInstance.kill()
  })
}
Countdown.prototype._format = function _format(dateInput) {
  switch (true) {
    case dateInput instanceof Date:
      return dateInput.getTime()

    case /^\d+$/.test(dateInput):
      return dateInput

    case /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}/.test(dateInput):
      return new Date(dateInput).getTime()

    default:
      console.warn('時間格式有誤: 請輸入"YYYY-MM-DD HH:mm:ss" 或 毫秒數 或 Date實體, 將使用當前時間', dateInput)
      return _format(new Date())
  }
}
Countdown.prototype._milliSecondToPattern = function _milliSecondToPattern(deltaMilliSecond) {
  var milliSecondMap = (function() {
    var milliSecond = 1
    var second = 1000 * milliSecond
    var minute = 60 * second
    var hour = 60 * minute
    var day = 24 * hour

    return {
      milliSecond: milliSecond,
      second: second,
      minute: minute,
      hour: hour,
      day: day
    }
  })()

  var modDay = Math.floor(deltaMilliSecond / milliSecondMap.day)
  var modHours = Math.floor((deltaMilliSecond % milliSecondMap.day) / milliSecondMap.hour)
  var modMinutes = Math.floor((deltaMilliSecond % milliSecondMap.hour) / milliSecondMap.minute)
  var modSeconds = Math.ceil((deltaMilliSecond % milliSecondMap.minute) / milliSecondMap.second)

  var days = __paddingZero(modDay, 2)
  var hours = __paddingZero(modHours, 2)
  var minutes = __paddingZero(modMinutes, 2)
  var seconds = __paddingZero(modSeconds, 2)

  return {
    text: days + ' ' + hours + ':' + minutes + ':' + seconds,
    map: {
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds
    }
  }

  function __paddingZero(inputNumber, paddingNumber) {
    inputNumber = inputNumber || 0
    paddingNumber = paddingNumber || 2
    var delta = paddingNumber - inputNumber.toString().length

    if (delta > 0) {
      var zeros = (new Array(delta)).fill().map(function() {
        return '0'
      }).join('')
      return zeros + inputNumber
    } else {
      return inputNumber.toString()
    }
  }
}

// 從預設年份資料往上增加，到了新的一年就將此新年資料加入
const generateSinceYearList = (sinceYear = [], currentYear = 2023) => {
  // 從原本 sinceYear 最新年份資料 + 1 開始計算
  for (let year = parseInt(sinceYear[sinceYear.length - 1].val) + 1; year <= currentYear; year++) {
    sinceYear.push({
      name: year + '年',
      val: year.toString()
    })
  }
}

const getThisAndLastYear = () => {
  const currentYear = dayjs().year()
  const lastYear = currentYear - 1
  return [currentYear, lastYear]
}

const imageSearchNoDataMsg = '很抱歉，您查找的期数已查无资料，请查阅最新的图纸'

export {
  Countdown_HomePage,
  Countdown,
  isPDSWAPP,
  sendMessageToPDSW,
  isMobileDevice,
  isIos,
  generateSinceYearList,
  getThisAndLastYear,
  imageSearchNoDataMsg
}
