import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d1210a1c = () => interopDefault(import('../pages/cltx.vue' /* webpackChunkName: "pages/cltx" */))
const _174f0e80 = () => interopDefault(import('../pages/images/index.vue' /* webpackChunkName: "pages/images/index" */))
const _2130140a = () => interopDefault(import('../pages/m/index.vue' /* webpackChunkName: "pages/m/index" */))
const _7285a1d8 = () => interopDefault(import('../pages/m/cltx.vue' /* webpackChunkName: "pages/m/cltx" */))
const _7ebad79f = () => interopDefault(import('../pages/m/images.vue' /* webpackChunkName: "pages/m/images" */))
const _a28c5688 = () => interopDefault(import('../pages/m/property.vue' /* webpackChunkName: "pages/m/property" */))
const _15057a79 = () => interopDefault(import('../pages/m/trend/_lottery_code.vue' /* webpackChunkName: "pages/m/trend/_lottery_code" */))
const _3adb924a = () => interopDefault(import('../pages/trend/_lottery_code.vue' /* webpackChunkName: "pages/trend/_lottery_code" */))
const _34ff25dd = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cltx",
    component: _d1210a1c,
    name: "cltx"
  }, {
    path: "/images",
    component: _174f0e80,
    name: "images"
  }, {
    path: "/m",
    component: _2130140a,
    name: "m"
  }, {
    path: "/m/cltx",
    component: _7285a1d8,
    name: "m-cltx"
  }, {
    path: "/m/images",
    component: _7ebad79f,
    name: "m-images"
  }, {
    path: "/m/property",
    component: _a28c5688,
    name: "m-property"
  }, {
    path: "/m/trend/:lottery_code?",
    component: _15057a79,
    name: "m-trend-lottery_code"
  }, {
    path: "/trend/:lottery_code?",
    component: _3adb924a,
    name: "trend-lottery_code"
  }, {
    path: "/",
    component: _34ff25dd,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
