import { getPublicCp } from '@/api/public/home'
import { getCpsDetail } from '@/api/desktop/home'
import { ballsComposition, formatLhName } from '@/utils/lhFormater'
import moment from 'moment'

// 過濾後端資料格式
const setLot = (apiData) => {
  return {
    name: formatLhName(apiData.prePeriod.code),
    code: apiData.prePeriod.code,
    period: apiData.prePeriod.period,
    // 下期開獎時間使用 utc 時間
    drawTime: apiData.nextPeriod && moment(apiData.nextPeriod.drawTime).utc().valueOf(),
    // 組合彩球資訊
    balls: ballsComposition({
      number: apiData.prePeriod.balls,
      seBo: apiData.prePeriod.ruleDetail.seBo,
      wuXing: apiData.prePeriod.ruleDetail.wuXing,
      shengXiao: apiData.prePeriod.ruleDetail.shengXiao
    }),
    tableInfo: {
      zongHe: apiData.prePeriod.ruleDetail.zongHe[0],
      zongHeDanShuang: apiData.prePeriod.ruleDetail.zongHeDanShuang[0],
      zongHeDaXiao: apiData.prePeriod.ruleDetail.zongHeDaXiao[0],
      zongHeYanSe: apiData.prePeriod.ruleDetail.chiSeBo,
      teMaDanShuang: apiData.prePeriod.ruleDetail.hmDanShuang[6],
      teMaDaXiao: apiData.prePeriod.ruleDetail.hmDaXiao[6]
    }
  }
}

const state = () => ({
  // 彩種清單
  cpsList: [],
  // 彩種基本資料 (頻率, 中文名, 休市日, icon)
  cpsDetail: {
    // 香港六合彩
    hk6: {},
    // 分分六合彩
    ff6: {},
    // 三分六合彩
    sf6: {},
    // 極速六合彩
    js6: {}
  }
})

const mutations = {
  SET_CPS_LIST(state, payload) {
    state.cpsList = payload
  },
  SET_CPS_DETAIL(state, payload) {
    // 全部六合彩彩種
    const lhcDetailKeys = Object.keys(payload).filter(lotteryCode => payload[lotteryCode].cpType === 'sixhc')
    const detailMap = {}
    lhcDetailKeys.forEach(lotteryCode => {
      detailMap[lotteryCode] = payload[lotteryCode]
    })
    state.cpsDetail = detailMap
  }
}

const actions = {
  // 取得彩種資訊
  async getCpsDetail({ state, commit, rootState, dispatch }, cpCode) {
    // cpCode 不傳值預設使用熱門彩種清單
    const [data, error] = await getCpsDetail({ cpCode: cpCode || rootState.app.hotLottery.join(',') })
    if (error) {
      console.error(`[error]:`, error)
      setTimeout(() => {
        dispatch('getCpsDetail', cpCode)
      }, 3000)
      return false
    }

    // for 個別彩種更新倒數開獎
    if (cpCode) {
      const lotteryIndex = state.cpsList.findIndex(lot => lot.code === cpCode)
      const oldLottery = state.cpsList[lotteryIndex]
      const currentLottery = data[cpCode].prePeriod
      const nextDrawTime = data[cpCode].nextPeriod.drawTime
      // 當前期數跟舊的一樣 或者 下期開獎時間小於當前時間 的話重新送 api
      if (oldLottery.period === currentLottery.period || moment(nextDrawTime).utc().valueOf() < moment().utc().valueOf()) {
        setTimeout(() => {
          dispatch('getCpsDetail', cpCode)
        }, 3000)
        return true
      }
      const returnList = [...state.cpsList]
      returnList.splice(lotteryIndex, 1, setLot(data[cpCode]))
      commit('SET_CPS_LIST', returnList)
      return true
    }

    // 按照熱門彩種排序
    commit('SET_CPS_LIST', rootState.app.hotLottery.map(lotCode => setLot(data[lotCode])))
    return true
  },
  async getPublicCp({ commit, dispatch }) {
    const [data, error] = await getPublicCp()

    if (error) {
      console.error(`[error]:`, error)
      setTimeout(() => {
        dispatch('getPublicCp')
      }, 3000)
    } else {
      commit('SET_CPS_DETAIL', { ...data })
      return true
    }
  }
}

export { state, mutations, actions }
