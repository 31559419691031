const state = () => ({
  name: ''
})

const mutations = {
  UPDATE_NAME(state, name) {
    state.name = name
  }
}

const actions = {
  async getDemoName({ commit }, payload) {
    const [data, error] = await Promise.resolve(['-name-', null])
    if (error) return [null, error]

    commit('UPDATE_NAME', data)
    return [data, null]
  }
}

export { state, mutations, actions }
