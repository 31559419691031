<template>
  <div class="container__header">
    <!-- 公告 -->
    <div class="statusbar">
      <div class="wrapper">
        <div class="statusbar__box">
          <div class="statusbar__box__left">
            <div class="marquee"><i class="marquee__icon"><img :src="require('@/assets/style/desktop/img/marquee-icon.svg')" alt=""></i><span class="marquee__title">最新公告：</span>
              <marquee class="marquee__message" direction="left" behavior="scroll" scrollamount="6" onmouseover="this.stop()" onmouseout="this.start()"><span class="text">{{ marquee }}</span></marquee>
            </div>
          </div>
          <div class="statusbar__box__right">
            <a class="mobile-link" href="/m">手机版</a>
          </div>
        </div>
      </div>
    </div>
    <div class="header">
      <div class="wrapper">
        <div class="header__box">

          <!-- logo -->
          <a v-if="logo.url" class="logo" :href="logo.url" target="_blank"><img :src="logo.imgPath" alt=""></a>
          <div v-else class="logo">
            <nuxt-link to="/">
              <img :src="logo.imgPath" alt="">
            </nuxt-link>
          </div>

          <div class="h-banner" style="width: 820px">
            <!-- 這邊先拔掉等後端補 url -->
            <!-- <img :src="topBanner.imgPath" alt=""> -->
          </div>
          <div class="qr-link"><img class="qr-link__icon" :src="require('@/assets/style/desktop/img/qr-icon.svg')" alt="">
            <div class="qr-link__text"><span class="text">扫一扫</span><span class="text">APP下载</span></div>
            <div class="qr-link__scanning">
              <div class="qr-link__scanning__img"><img :src="qrCode" alt=""></div><span class="qr-link__scanning__text">扫一扫,下载移动客户端</span><span class="qr-link__scanning__text">（目前仅供安卓下载, iOS敬请期待）</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 導航欄 -->
    <div class="headermenubar">
      <div class="wrapper">
        <ul class="headermenubar__box">
          <li class="menu-item" :class="{'active': this.$route.path==='/'}">
            <nuxt-link to="/" class="menu-item__link">
              <span class="menu-item__text">首页</span>
            </nuxt-link>
          </li>
          <li class="menu-item" :class="{'active': /^\/trend\/*/.test(this.$route.path)}">
            <div class="menu-item__link">
              <span class="menu-item__text">走势图表</span>
              <i class="menu-item__icon"></i>
            </div>
            <div class="menu-item__subbox-2">
              <nuxt-link
                v-for="(lotteryCode, index) in hotLottery"
                :key="`cp-${index}`"
                class="menu-item__subbox-2__item"
                :to="`/trend/${lotteryCode}`"
              >
                <span class="subbox-2__item__text">{{ cpsDetail[lotteryCode].name }}</span>
              </nuxt-link>
            </div>
          </li>
          <li class="menu-item" :class="{'active': this.$route.path==='/cltx'}">
            <nuxt-link to="/cltx" class="menu-item__link">
              <span class="menu-item__text">长龙提醒</span>
            </nuxt-link>
          </li>
          <li class="menu-item" :class="{'active': this.$route.path==='/images'}">
            <nuxt-link to="/images" class="menu-item__link">
              <span class="menu-item__text">六合图库</span>
            </nuxt-link>
          </li>
          <li class="menu-item"><a class="menu-item__link" href="" @click.prevent="pushRoute()"><span class="menu-item__text">开奖网</span></a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { formatLhName } from '@/utils/lhFormater'

export default {
  name: 'WebHeader',
  computed: {
    // 彩種 Map
    cpsDetail() {
      return this.$store.state.lottery.cpsDetail
    },
    // 熱門彩種
    hotLottery() {
      return this.$store.state.app.hotLottery
    },
    // 跑馬
    marquee() {
      return this.$store.state.app.marquee
    },
    // header 圖片
    logo() {
      return this.$store.state.app.logo
    },
    topBanner() {
      return this.$store.state.app.topBanner
    },
    // app 下載連結
    qrCode() {
      return this.$store.state.app.apkLink.qrCode
    },
    HeaderTitle() {
      // 首頁設置 api 站名
      if (this.$route.path === '/') {
        return this.$store.state.app.siteName
      } else if (this.$route.path.includes('/trend')) {
        // 設置 trend 標題
        return formatLhName(this.$route.params.lottery_code)
      } else if (this.$route.path.includes('/images')) {
        return '六合图库'
      } else if (this.$route.path.includes('/cltx')) {
        return '长龙提醒'
      }

      return '六合标题'
    },
    // 判斷是否為走勢頁路由
    isTrend() {
      return this.$route.path.includes('/trend')
    }
  },
  methods: {
    pushRoute() {
      window.open(process.env.MARKSIX_SERVER_HOST)
    }
  }
}
</script>

