<template>
  <!-- 背景暫時用 style -->
  <div class="container">
    <!-- header -->
    <WebHeader />

    <!-- content -->
    <div class="container__content">
      <div class="wrapper">
        <nuxt />
      </div>
    </div>

    <!-- footer -->
    <WebFooter />

    <div v-show="isScrolled" class="fixed-position">
      <fix-top-button @click.native="scrollToTop" />
    </div>
  </div>
</template>

<script>
import WebHeader from '@/components/desktop/layout/WebHeader'
import WebFooter from '@/components/desktop/layout/WebFooter'
import fixTopButton from '@/components/desktop/common/fixTopButton'

export default {
  name: 'DesktopLayout',
  components: {
    WebHeader,
    WebFooter,
    fixTopButton
  },
  data() {
    return {
      isScrolled: false
    }
  },
  mounted() {
    const vm = this
    window.onscroll = function() {
      vm.isScrolled = window.scrollY > 0
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  },
  head() {
    return {
      title: this.$store.state.app.siteName || '六合彩开奖网',
      // %s 是注入上面的 title
      // titleTemplate: `${this.$store.state.app.siteName} %s`,
      link: [
        { rel: 'stylesheet', href: '/desktop/css/main.css' }
      ],
      meta: [{
        hid: 'description',
        name: 'description',
        content: this.$store.state.seo.metaDescription
      }, {
        hid: 'keywords',
        name: 'keywords',
        content: this.$store.state.seo.metaKeyword
      }]
      // style: [
      //   { type: 'text/css', cssText: require('@/assets/style/desktop/css/main.css') }
      // ]
    }
  }
}
</script>

<style lang="scss" scoped>
.fixed-position {
  position: fixed;
  bottom: 32Px;
  right: calc(50% - 600Px - 40Px - 32Px);
}
@media screen and (max-width: 1242px){
  .fixed-position {
    right: 32Px;
  }
}
</style>
