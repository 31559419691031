import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'
import { Checkbox, DatePicker, RadioGroup, Radio, Dialog, Popover, Select, Option, Input, Tabs, TabPane, Loading, Image, Button } from 'element-ui'

Vue.component(RadioGroup.name, RadioGroup)
Vue.component(Radio.name, Radio)
Vue.component(Checkbox.name, Checkbox)
Vue.component(DatePicker.name, DatePicker)
Vue.component(Dialog.name, Dialog)
Vue.component(Popover.name, Popover)
Vue.component(Select.name, Select)
Vue.component(Option.name, Option)
Vue.component(Input.name, Input)
Vue.component(Tabs.name, Tabs)
Vue.component(TabPane.name, TabPane)
Vue.component(Image.name, Image)
Vue.component(Button.name, Button)
Vue.use(Loading)
