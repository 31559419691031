const state = () => ({

})

const mutations = {

}

const actions = {
  async nuxtServerInit({ dispatch }, { route }) {
    // 判斷是否為手機版
    await dispatch('app/getSitoInfo', route.path.includes('/m'))

    await dispatch('lottery/getPublicCp')
  }
}

export { state, mutations, actions }
