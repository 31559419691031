<template>
  <div class="rule-popup" :class="{'is-active': lotteryInfo.popupIsOpen}">
    <div class="popup-mask">
      <div class="popup">
        <div class="popup__header">
          <div class="popup__header__top">
            <div class="header-title">
              <img class="title-icon" src="@/assets/style/mobile/img/popup/iconSetUp@2x.png" alt="">
              <p class="title-text">说明</p>
            </div>
            <a class="header-close">
              <img class="close-icon" src="@/assets/style/mobile/img/popup/iconPopupCross@2x.png" alt="" @click="closePopup">
            </a>
          </div>
        </div>
        <div class="popup__body">
          <div class="rule-list">
            <p>1. 总和: 所有七个开奖号码加总</p>
            <p>◎大小: 大于或等于175，小于或等于174</p>
            <p>◎单双: 能被2整除的数为“双”，反之，为“单”</p>
            <p>2. 七色波：以开出的7个色波，那种颜色最多为中奖。 开出的6个正码各以1个色波计，特别号以1.5个色波计。</p>
            <p>以下3种结果视为和局</p>
            <p>6个正码开出3蓝3绿，而特别码是1.5红</p>
            <p>6个正码开出3蓝3红，而特别码是1.5绿</p>
            <p>6个正码开出3绿3红，而特别码是1.5蓝</p>
            <p>3. 特码</p>
            <p>◎大小: 01~24为小，25~48为大，49则为和</p>
            <p>◎单双: 能被2整除的数为“双”，反之，为“单”, 49则视为“和”</p>
            <p>4. 特码合数:  特码的个位加上十位之和</p>
            <p>◎大小: 大于或等于7为大，和数小于或等于6为小, 特码为49时视为和</p>
            <p>◎单双: 能被2整除的数为双，反之，为单，特码为49时视为和</p>
            <p>5. 特码尾数大小: 5尾~9尾为大，0尾~4尾为小，特码为49时视为和</p>
          </div>
        </div>
        <div class="popup__footer">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RulePopup',
  props: {
    lotteryInfo: {
      type: Object,
      required: true
    }
  },
  methods: {
    closePopup() {
      this.lotteryInfo.popupIsOpen = false
    }
  }
}
</script>

<style scoped>
  .rule-popup {
    display: none;
  }
  .is-active {
    display: block;
  }
</style>
