import { getSEO } from '@/api/public/basic'

const state = () => ({
  metaKeyword: '',
  metaDescription: ''
})

const mutations = {
  SET_SEO_INFO: (state, { metaKeyword = '', metaDescription = '' }) => {
    state.metaKeyword = metaKeyword
    state.metaDescription = metaDescription
  }
}

const actions = {
  async getSEO({ commit }, route) {
    const [seoInfo, error] = await getSEO({ routing: route.fullPath })
    if (error) return

    commit('SET_SEO_INFO', seoInfo)
  }
}

export { state, mutations, actions }
