import { getHomeInfo } from '@/api/mobile/home'
import { getHomeInfo as getHomeInfoDesktop } from '@/api/desktop/home'

const state = () => ({
  // 首頁 header 站點名稱
  siteName: '',
  // 跑馬
  marquee: '',
  // 輪播
  bannerList: [{ imgPath: '', url: '' }, { imgPath: '', url: '' }],
  // 熱門彩種
  hotLottery: [],
  // 每個彩種底下有的圖庫
  tuku: {},
  // 廣告
  sidebarAds: [
    { imgPath: '', url: '' }
  ],
  // header 圖片
  logo: { imgPath: '', url: '' },
  topBanner: { imgPath: '', url: '' },
  // app 軟件下載連結
  apkLink: { qrCode: '', url: '' }
})

const mutations = {
  SET_SITE_INFO(state, payload) {
    state.siteName = payload.siteName
    state.marquee = payload.marquee
    state.bannerList = payload.bannerList
    state.hotLottery = payload.hotLottery
    state.tuku = payload.tuku
    state.sidebarAds = payload.sidebarAds
    state.logo = payload.logo
    state.topBanner = payload.topBanner
    state.apkLink = payload.apkLink
  }
}

const actions = {
  async getSitoInfo({ commit }, isMobile) {
    const sendApi = isMobile ? getHomeInfo : getHomeInfoDesktop
    const [data, error] = await sendApi()
    if (error) {
      console.error(`[error]:`, error)
      return false
    } else {
      commit('SET_SITE_INFO', {
        siteName: data.siteName || '',
        // 做一點 siteSetting = null 的防呆
        marquee: data.siteSetting && data.siteSetting.marquee || '',
        bannerList: data.siteSetting && data.siteSetting.promotionBanners || [],
        hotLottery: data.siteSetting && data.siteSetting.hotLottery || ['hk6', 'ff6', 'sf6', 'js6'],
        tuku: data.siteSetting && data.siteSetting.tuku || {},
        sidebarAds: data.siteSetting && data.siteSetting.sidebarAds || [{ imgPath: '', url: '' }],
        logo: data.siteSetting && data.siteSetting.logo || { imgPath: '', url: '' },
        topBanner: data.siteSetting && data.siteSetting.topBanner || { imgPath: '', url: '' },
        apkLink: data.siteSetting && data.siteSetting.apkLink || { qrCode: '', url: '' }
      })
      return true
    }
  }
}

export { state, mutations, actions }
